import React, { useEffect, useRef, useState } from "react";
import "./home.css";
import car from "../../assets/images/goldcar.png";

import star from "../../assets/images/Star.svg";
import card1 from "../../assets/images/card2.svg";
import one from "../../assets/images/one.png";
import two from "../../assets/images/two.png";
import bhagavan from "../../assets/images/bhagavan.svg";
import swathi from "../../assets/images/swathi.svg";

import three from "../../assets/images/three.png";
import four from "../../assets/images/four.png";
import caro from "../../assets/images/carblink.png";
import easy from "../../assets/images/easy.png";
import swift from "../../assets/images/swift.png";
import intrest from "../../assets/images/intrest.png";
import lock from "../../assets/images/lock.png";
import idfc from "../../assets/images/idfc.png";
import finc from "../../assets/images/fincorp.png";
import axis from "../../assets/images/axis.png";
import hdfc from "../../assets/images/hdfc.png";
import yes from "../../assets/images/yes.png";
import touch from "../../assets/images/touch.png";
import sai from "../../assets/images/sai.png";
import btn from "../../assets/images/btn.png";
import arrow from "../../assets/images/arrow.png";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import quote from "../../assets/images/quote.svg";
import { useNavigate } from "react-router-dom";
import { HowItWorks } from "../HowItWorks/HowItWorks";

const HomeScreen = () => {
  const [dropdownVisibility, setDropdownVisibility] = useState({});
  const [data, setData] = useState([]);
  const [arrowDisable, setArrowDisable] = useState(true);
  
  const elementRef = useRef(null);

  const reviewref = useRef(null)

  const navigate = useNavigate();
  const [email, setEmail] = useState('');

  const handleInputChange = (e) => {
    setEmail(e.target.value);
  };

  const handleFormSubmit = (e) => {

    e.preventDefault();
    var formData = new FormData(document.getElementById('uploadForm'));

    const data = {
      'email': email,
    };
    console.log('********************');
    console.log(formData);

    fetch('https://www.aadhyacarloans.com/api/subscribe', {
      method: 'POST',
      // headers: {
      //   'Content-Type': 'application/json',
      // },
      body: formData, //JSON.stringify(data),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((responseData) => {
        console.log(responseData);
      })
      .catch((error) => {
        console.error('Error:', error);
      });

    setEmail("");

  };
  const handleHorizantalScroll = (element, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
      if (element.scrollLeft === 0) {
        setArrowDisable(true);
      } else {
        setArrowDisable(false);
      }
    }, speed);
  };

  const toggleDropdown = (id) => {
    setDropdownVisibility((prevVisibility) => ({
      ...prevVisibility,
      [id]: !prevVisibility[id] || false,
    }));
  };
  const fetchdata = async () => {
    const URL = "https://jsonplaceholder.typicode.com/users";
    try {
      const response = await fetch(URL).then((res) => res.json());
      console.log(response);
      setData(response);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchdata();
  }, []);
  return (
    <div className="home">
      <div className="home-main">
        <img src={car} alt="car" className="goldcar" />
        <div className="sub-main">
          <p className="heading">
            Find financial assistance with{" "}
            <strong className="aadya">AAdhya Associates</strong> for your dream
            car!
          </p>
          <p className="para">
            We&#39;re dedicated to helping you transform your ideas and stories
            into captivating audio experiences.
          </p>
          <div >
          <form onSubmit={handleFormSubmit} id="uploadForm" className="loan-eligibility">

          <input
          className="text"
          required
          type="email"
          name="email"
          value={email}
          placeholder="Your e-mail address"
          onChange={handleInputChange}
        />
            <button type="submit">
              Check Loan Eligibiloty
            </button>
            </form>
          </div>
          <div className="feature-list">
            <div className="item">
              <img className="star" alt="Star" src={star} />
              <div className="text">Enhanced Online Visibility</div>
            </div>
            <div className="item">
              <img className="star" alt="Star" src={star} />
              <div className="text">Customized Strategies</div>
            </div>
            <div className="item">
              <img className="star" alt="Star" src={star} />
              <div className="text">Improved Branding</div>
            </div>
          </div>

          <div className="featured-clients">
            <div className="images">
              <div className="client-img">
                <img className="testimonial" alt="Testimonial" src={one} />
                <img className="img" alt="Testimonial" src={two} />
                <img className="testimonial-2" alt="Testimonial" src={three} />
                <img
                  className="img"
                  alt="Testimonial"
                  src="testimonial-04.png"
                />
                <img className="testimonial-2" alt="Testimonial" src={four} />
                <img className="testimonial-3" alt="Testimonial" src={two} />
              </div>
            </div>
            <p className="text">
              1354+ Delighted Clients Share Their Success Stories
            </p>
          </div>
        </div>
      </div>
      <div className="second-sec">
        <div className="group-3">
          <p className="instant-approval-for">
            <span className="span">Instant Approval for</span>
            <span className="text-wrapper-5">&nbsp;</span>
            <span className="text-wrapper-6">Car Loan</span>
          </p>
          <p className="get-the-best-quoted">
            Get The Best Quoted Car Loans With Easy And Speedy Approval With The
            Lowest Interest Rates For <br />
            suitable Emi Options
          </p>
        </div>
        <>
          <div class="button-contianer">
            <button
              style={{
                width: "60px",
                height: "60px",
                borderRadius: "50%",
                backgroundColor: arrowDisable ? "#01020273" : "#3a5acf73",
                border: "4px solid white",
              }}
              onClick={() => {
                handleHorizantalScroll(elementRef.current, 35, 100, -10);
              }}
              disabled={arrowDisable}
            >
              <KeyboardArrowLeftIcon
                sx={{ color: "white", fontSize: "40px" }}
              />
            </button>
            <button
              style={{
                width: "60px",
                height: "60px",
                borderRadius: "50%",
                backgroundColor: "#3a5acf73",
                border: "4px solid white",
              }}
              onClick={() => {
                handleHorizantalScroll(elementRef.current, 35, 100, 10);
              }}
            >
              <KeyboardArrowRightIcon />
            </button>
          </div>
          <div
            style={{
              display: "flex",
              gap: "10px",
              width: "100%",
              overflow: "hidden",
            }}
            ref={elementRef}
            class="img-container"
          >
            {data.map((placement) => (
              <div loading="lazy" key={placement.id}>
                <div className="div-3">
                  <div
                    style={{
                      backgroundColor: "transparent",
                      borderRadius: "30px",
                      boxShadow: "4px 4px 4px #00000040",
                      height: "200px",

                      width: "320px",
                    }}
                  >
                    <img src={card1} alt="card" style={{ width: "300px" }} />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
        <div className="eligibility">ELIGIBILITY</div>
        <div className="cards-sec">
          <div className="cards-annual">
            <p>
              Annual Income <strong>x</strong> 3 Times
            </p>
          </div>
          <div className="cards-annual">
            <p>
              Minimum Salary -<strong> 3 Lacs</strong>
            </p>
            <br />
            <p>
              Minimum ITR -<strong>3.5 Lacs</strong>
            </p>
          </div>
          <div className="cards-annual">
            <p>
              Customer Age <strong> 21-70 Years</strong>
            </p>
          </div>
          <div className="cards-annual">
            <p>
              Asset Age- <strong>2013-Till Now</strong>
            </p>
          </div>

          <div className="buttons-cards">
            <div className="button1">
              <HowItWorks /> <img src={arrow} alt="arrow" />
            </div>
            <button className="button2" onClick={() => navigate("/loan")}>
              Apply Now
            </button>
          </div>
        </div>
      </div>
      <div className="reason">Reason Why Choose Us? </div>
      <div className="car-demo">
        <div className="car-left">
          <div className="car-head-left">
            <span className="we-provide">
              wE DEDICATED TO pROVIDE <br />
            </span>
            <span className="quality">qUALITY sERVICES</span>
          </div>
          <img className="caro" src={caro} alt="car" />
          <div className="howit-work">
            <HowItWorks />
          </div>
        </div>
        <div className="car-right">
          <div className="car-card">
            <img src={easy} alt="easy" />
            <p className="easy"> Easy Loan Processing</p>
            <p className="second-easy">
              Streamlined and hassle-free loan application process for swift and
              efficient approval, ensuring a stress-free experience for all
              applicants.
            </p>
          </div>
          <div className="car-card">
            <img src={swift} alt="easy" />
            <p className="easy">Swift approval</p>
            <p className="second-easy">
              Streamlined and hassle-free loan application process for swift and
              efficient approval, ensuring a stress-free experience for all
              applicants.
            </p>
          </div>
          <div className="car-card">
            <img src={intrest} alt="easy" />
            <p className="easy">Lower Interest Rates</p>
            <p className="second-easy">
              Streamlined and hassle-free loan application process for swift and
              efficient approval, ensuring a stress-free experience for all
              applicants.
            </p>
          </div>
          <div className="car-card">
            <img src={lock} alt="easy" />
            <p className="easy">Flexible EMI Options</p>
            <p className="second-easy">
              Streamlined and hassle-free loan application process for swift and
              efficient approval, ensuring a stress-free experience for all
              applicants.
            </p>
          </div>
        </div>
      </div>
      <div className="bank">
        <p className="banking"># Banking Partners</p>
        <div className="all-banks">
          <div className="banks">
            <img src={hdfc} alt="hdfc" />
            <img src={idfc} alt="hdfc" />
            <img src={axis} alt="hdfc" />
            <img src={finc} alt="hdfc" />
            <img src={yes} alt="hdfc" />
          </div>
          <div className="banks">
            <img src={hdfc} alt="hdfc" />
            <img src={idfc} alt="hdfc" />
            <img src={axis} alt="hdfc" />
            <img src={finc} alt="hdfc" />
            <img src={yes} alt="hdfc" />
          </div>
        </div>
      </div>

      <div className="touch-main">
        <img src={touch} alt="touch" />
        <div className="touch-right">
          <p className="get-in">get In Touch With your Mail</p>
          <div >
          <form onSubmit={handleFormSubmit} id="uploadForm" className="form-in">

          <input
          className="text"
          required
          type="email"
          name="email"
          value={email}
          placeholder="Your e-mail address"
          onChange={handleInputChange}
        />
            <button type="submit" >Subscribe</button>
            </form>
          </div>
        </div>
      </div>
      <div className="reviews-home">
        <p className="client-reviews">What Clients Say Us</p>
        <div class="button-contianer review-bt" style={{width:"12%",top:0,}}>
            <button
              style={{
                width: "60px",
                height: "60px",
                borderRadius: "50%",
                backgroundColor: arrowDisable ? "#01020273" : "#3a5acf73",
                border: "4px solid white",
              }}
              onClick={() => {
                handleHorizantalScroll(reviewref.current, 35, 100, -10);
              }}
              disabled={arrowDisable}
            >
              <KeyboardArrowLeftIcon
                sx={{ color: "white", fontSize: "40px" }}
              />
            </button>
            <button
              style={{
                width: "60px",
                height: "60px",
                borderRadius: "50%",
                backgroundColor: "#3a5acf73",
                border: "4px solid white",
                position:"relative",
                right:"-770%"
              }}
              onClick={() => {
                handleHorizantalScroll(reviewref.current, 35, 100, 10);
              }}
            >
              <KeyboardArrowRightIcon />
            </button>
          </div>
        <div className="reviews-sec" ref={reviewref}>
          <div className="review-card">
            <div className="first-review">
              <div className="image-review" >
                <img src={sai} alt="sai" />
                <div className="name-review">
                  <p className="rajesh">Rajesh</p>
                  <p className="mail">xxxxx@gmail.com</p>
                </div>
              </div>
              <img src={quote} alt="quote" className="quote" />
            </div>
            <p className="second-review">
              Securing a car loan with Aadhya Associates was a breeze. The
              process was swift, and the team's expertise made it stress-free.
              Aadhya Associates truly understands their client's needs,
              providing an excellent service that I highly recommend to anyone
              seeking hassle-free car financing.
            </p>
          </div>
          <div className="review-card">
            <div className="first-review">
              <div className="image-review">
                <img src={bhagavan} alt="sai" />
                <div className="name-review">
                  <p className="rajesh">Bhagavan</p>
                  <p className="mail">xxxxx@gmail.com</p>
                </div>
              </div>
              <img src={quote} alt="quote" className="quote" />
            </div>
            <p className="second-review">
              Aadhya Associates not only streamlined my car loan process but
              also offered an exceptional balance transfer service. Their
              efficient team facilitated a seamless transfer with competitive
              rates, saving me both time and money.
            </p>
          </div>
          <div className="review-card">
            <div className="first-review">
              <div className="image-review">
                <img src={swathi} alt="sai" />
                <div className="name-review">
                  <p className="rajesh">Swathi</p>
                  <p className="mail">xxxxx@gmail.com</p>
                </div>
              </div>
              <img src={quote} alt="quote" className="quote" />
            </div>
            <p className="second-review">
              Aadhya Associates exceeded my expectations with their remarkable
              balance transfer service, offering a 200% top-up loan on my
              existing car loan. The process was efficient, and their
              competitive rates saved me a significant amount of money.
            </p>
          </div>
        </div>
      </div>
      <div className="frequent">
        <div className="faq">
          <h1 className="matter">Frequently Asked Questions</h1>
          <p className="sub">
            {" "}
            Let's explore how we can help your business thrive in the
            ever-evolving marketing landscape.
          </p>
          <button className="faqb" onClick={() => navigate("/loan")}>
            Apply Now <img src={btn} alt="" />{" "}
          </button>
        </div>
        <div className="drop">
          <div class="dropdown">
            <button
              class="dropdown-button"
              onClick={() => toggleDropdown("dropdown1")}
            >
              What is a car loan, and how does it work?
            </button>
            {dropdownVisibility["dropdown1"] ? (
              <div class="dropdown-content">
                <p>
                  A car loan is a financial product that allows individuals to
                  borrow money for the purpose of purchasing a vehicle. The
                  borrower repays the loan amount in installments over a
                  specified period, usually with interest.
                </p>
              </div>
            ) : null}
          </div>
          <div class="dropdown">
            <button
              class="dropdown-button"
              onClick={() => toggleDropdown("dropdown2")}
            >
              Do I need collateral for a car loan from Aadhya Associates?
            </button>
            {dropdownVisibility["dropdown2"] ? (
              <div class="dropdown-content">
                <p>
                  Typically, the car being financed serves as the collateral for
                  the loan. However, it's always best to check the specific
                  terms and conditions of the loan agreement.
                </p>
              </div>
            ) : null}
          </div>

          <div class="dropdown">
            <button
              class="dropdown-button"
              onClick={() => toggleDropdown("dropdown3")}
            >
              Can I get a loan to purchase a used car through Aadhya Associates?
            </button>
            {dropdownVisibility["dropdown3"] ? (
              <div class="dropdown-content">
                <p>
                  Yes, Aadhya Associates provides loans for both new and used
                  cars, offering flexibility to clients based on their
                  preferences and requirements.
                </p>
              </div>
            ) : null}
          </div>
          <div class="dropdown">
            <button
              class="dropdown-button"
              onClick={() => toggleDropdown("dropdown4")}
            >
              What factors determine the eligibility for a car loan?
            </button>
            {dropdownVisibility["dropdown4"] ? (
              <div class="dropdown-content">
                <p>
                  Eligibility is often based on factors such as income, credit
                  score, employment stability, and the borrower's ability to
                  repay the loan.
                </p>
              </div>
            ) : null}
          </div>
          <div class="dropdown">
            <button
              class="dropdown-button"
              onClick={() => toggleDropdown("dropdown5")}
            >
              How long does it take to get approval for a car loan?
            </button>
            {dropdownVisibility["dropdown5"] ? (
              <div class="dropdown-content">
                <p>
                  The approval timeline may vary, but we strive to ensure a
                  quick and efficient process. The completeness of documentation
                  and meeting eligibility criteria play a significant role.
                </p>
              </div>
            ) : null}
          </div>
          <div class="dropdown">
            <button
              class="dropdown-button"
              onClick={() => toggleDropdown("dropdown6")}
            >
              Can I apply for a car loan if I have a low credit score?
            </button>
            {dropdownVisibility["dropdown6"] ? (
              <div class="dropdown-content">
                <p>
                  Aadhya Associates works with clients across various credit
                  scores. While a higher credit score may offer more favorable
                  terms, individuals with lower credit scores may still be
                  eligible for a car loan.
                </p>
              </div>
            ) : null}
          </div>
          <div class="dropdown">
            <button
              class="dropdown-button"
              onClick={() => toggleDropdown("dropdown7")}
            >
              Are there any hidden fees associated with car loans from Aadhya
              Associates?
            </button>
            {dropdownVisibility["dropdown7"] ? (
              <div class="dropdown-content">
                <p>
                  We are transparent about our terms and conditions. Our
                  commitment is to ensure a clear understanding of all fees and
                  charges associated with the car loan, and we do not impose
                  hidden fees.
                </p>
              </div>
            ) : null}
          </div>
          <div class="dropdown">
            <button
              class="dropdown-button"
              onClick={() => toggleDropdown("dropdown8")}
            >
              How does the car loan process work with Aadhya Associates?
            </button>
            {dropdownVisibility["dropdown8"] ? (
              <div class="dropdown-content">
                <p>
                  We facilitate all types of New & Used car loans, Car loan
                  Takeovers, and Cash on Car transactions. Our process is
                  streamlined and customer-friendly.
                </p>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeScreen;
