import { Route, Routes } from "react-router-dom";
import "./App.css";
// import { Footer } from "./components/Footer/Footer";
import { Header } from "./components/Header/Header";
import HomeScreen from "./screens/Home/HomeScreen";
import About from "./screens/About/About";
import { BlogPost } from "./screens/BlogPost";
import { HowItWorks } from "./screens/HowItWorks/HowItWorks";
import Loan from "./screens/Loan/Loan";
import Services from "./screens/services/Services";
import { Posts } from "./screens/post/Posts";
import FormComponent from "./components/FormComponent";
import Footer from "./components/Footer/Footer";

function App() {
  return (
    <div style={{ background: "black" }}>
      <Header />
      <Routes>
        <Route path="/" element={<HomeScreen />} />
        <Route path="/about" element={<About />} />
        <Route path="/blog/:blogId" element={<BlogPost />} />
        <Route path="/howitworks" element={<HowItWorks />} />
        <Route path="/loan" element={<Loan />} />
        <Route path="/services" element={<Services />} />
        <Route path="/posts" element={<Posts />} />
        <Route path="/formm" element={<FormComponent />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
