import React, { useState } from "react";
import "./aadhya_footer.css";
import aadhyalogo from "../../assets/images/aadhyalogo.svg";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import { Link } from "react-router-dom";
import { FormatColorFill } from "@mui/icons-material";
const Footer = () => {
  const [email, setEmail] = useState('');

  const handleInputChange = (e) => {
    setEmail(e.target.value);
  };

  const handleFormSubmit = (e) => {

    e.preventDefault();
    var formData = new FormData(document.getElementById('uploadForm'));

    const data = {
      'email': email,
    };
    console.log('********************');
    console.log(formData);

    fetch('https://www.aadhyacarloans.com/api/subscribe', {
      method: 'POST',
      // headers: {
      //   'Content-Type': 'application/json',
      // },
      body: formData, //JSON.stringify(data),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((responseData) => {
        console.log(responseData);
      })
      .catch((error) => {
        console.error('Error:', error);
      });

    setEmail("");

  };

  return (
    <div className="aadhya-container">
      <div className="aadya-footer-container">
        <div className="footer-img">
          <div className="footer-logo">
            <img src={aadhyalogo} alt="" />
            <p>
              Reinventing the way of creating websites, we aim to create the
              most master-peaced WordPress theme available on the market.
            </p>
          </div>

          <div className="social-links">
            <h5>Social Links</h5>
            <div className="links">
              <a
                style={{
                  listStyle: "none",
                  color: "white",
                }}
                href="https://www.facebook.com/theaadhyaassociates"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FacebookOutlinedIcon
                  style={{
                    width: "50px",
                    marginTop: "-8px",
                    marginLeft: "-20px",
                  }}
                />
              </a>
              <a
                style={{
                  listStyle: "none",
                  color: "white",
                }}
                href="https://www.instagram.com/theaadhyaassociates/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <InstagramIcon
                  style={{
                    width: "50px",
                    marginTop: "-8px",
                    marginLeft: "-15px",
                  }}
                />
              </a>
              <a
                style={{
                  listStyle: "none",
                  color: "white",
                }}
                href="https://www.linkedin.com/company/theaadhyaassociate/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LinkedInIcon
                  style={{
                    width: "50px",
                    marginTop: "-8px",
                    marginLeft: "-15px",
                  }}
                />
              </a>
            </div>
          </div>
        </div>

        <div className="contact-container">
          <h5 className="ch5">Contact Us</h5>
          <ul>
            <li>near meetings restaurant, </li>
            <li>Visalakshi Nagar, </li>
            <li>Visakhapatnam,Ap </li>
            <li>
              Call Us: <span>8790262442</span>
            </li>
            <li>theaadhyaassociates@gmail.com</li>
          </ul>
        </div>

        <div className="sineup-maps-container">
          <div className="sign-up">
            <h5>Sign Up for Email Updates</h5>
            <form onSubmit={handleFormSubmit} id="uploadForm">
        <input
          className="text"
          required
          type="email"
          name="email"
          value={email}
          placeholder="Your e-mail address"
          onChange={handleInputChange}
        />
        <button type="submit">
          <span>Get Back</span>
        </button>
      </form>
            <div className="sign-para">
              <p>Sign up with your email address to receive news and updates</p>
            </div>
            <div className="mapouter">
              <div className="gmap_canvas">
                <iframe
                  className="gmap_iframe"
                  width="420px"
                  height="135px"
                  frameBorder="0"
                  scrolling="no"
                  marginHeight="0"
                  marginWidth="0"
                  id="gmaps"
                  src="https://maps.google.com/maps?width=300&amp;height=220&amp;hl=en&amp;q=Aiswarya Enclave, TPT Colony Main Road, Seethammadara, Visakhapatnam - 530013&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="hr-container">
        <div>
          <hr />
        </div>
        <div className="copy-rights">
          <span>Copyright ©2023. All rights reserved.</span>
          <ul>
            <Link
              className="link"
              divClassName="instance-node"
              text="Home"
              to="/"
            >
              Home
            </Link>
            <Link className="link" text="About" to="/about">
              About
            </Link>
            <Link className="link" text="About" to="/services">
              Services
            </Link>
            <Link
              className="link"
              divClassName="instance-node"
              text="Blog Post"
              to="/posts"
            >
              Blog post
            </Link>
            <Link className="link" text="About" to="/loan">
              Eligibility
            </Link>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Footer;
